import React from 'react';
import styles from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueue.module.scss';
import CurrentlyPlaying from 'components/Player/AudioPlayerWrapper/PlayQueue/CurrentlyPlaying';
import NextInQueueList from 'components/Player/AudioPlayerWrapper/PlayQueue/NextInQueueList';
import NextInSeriesList from 'components/Player/AudioPlayerWrapper/PlayQueue/NextInSeriesList';
import NextInRecommendationsList from './NextInRecommendationsList/NextInRecommendationsList';
import { useTranslation } from 'hooks/useTranslation';
import { useTunnusContext } from 'contexts/TunnusContext';
import LoginPromotion from 'components/Player/AudioPlayerWrapper/PlayQueue/LoginPromotion';
import EmptyPlayQueue from 'components/Player/AudioPlayerWrapper/PlayQueue/EmptyPlayQueue';
import { QUEUE_PAGE_SIZE, usePlayerState } from 'contexts/PlayerStateContext';
import { Card } from '@yleisradio/areena-types';
import { DialogTitle } from 'components/Dialog/Dialog';
import { useCards } from 'hooks/useCards';
import { useFeatureFlag } from 'contexts/FeatureFlagContext/FeatureFlagContext';

type Props = {
  ongoingCard: Card | undefined;
};

export function PlayQueue({ ongoingCard }: Props) {
  const {
    activePlayer,
    queue,
    recommendationsQueue,
    recommendationsAnalyticsContext,
    showRecommendationsQueue,
    setShowRecommendationsQueue,
  } = usePlayerState();
  const t = useTranslation();
  const { isAuthenticated } = useTunnusContext();
  const { playQueue2024 } = useFeatureFlag();

  const cardsInQueue = Boolean(ongoingCard || queue?.length);
  const {
    cards: seriesCards,
    loading,
    analyticsContext,
  } = useCards({
    source: activePlayer?.playlistSource,
    pageIndex: 0,
    pageSize: QUEUE_PAGE_SIZE,
  });

  return (
    <div className={styles.queue}>
      <DialogTitle asChild>
        <h2 className={styles.title}>{t('queue')}</h2>
      </DialogTitle>

      {cardsInQueue && isAuthenticated && (
        <>
          {playQueue2024 && <h3>PlayQueue 2024 Feature is enabled</h3>}
          <CurrentlyPlaying />
          {queue.length > 0 && <NextInQueueList itemIds={queue} />}
          {seriesCards.length > 0 && (
            <NextInSeriesList
              heading={t('nextInSeries')}
              cards={seriesCards}
              analyticsContext={analyticsContext}
              listKey="play-queue-next-in-series"
              loading={loading}
            />
          )}
          {recommendationsQueue.length > 0 && (
            <NextInRecommendationsList
              heading={t('recommendationsInQueue')}
              cards={recommendationsQueue}
              analyticsContext={recommendationsAnalyticsContext}
              listKey="play-queue-recommendations"
              showRecommendationsQueue={showRecommendationsQueue}
              setShowRecommendationsQueue={setShowRecommendationsQueue}
            />
          )}
        </>
      )}
      {!cardsInQueue && isAuthenticated && <EmptyPlayQueue />}
      {!isAuthenticated && <LoginPromotion />}
    </div>
  );
}
