import * as Dialog from '@radix-ui/react-dialog';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import classNames from 'classnames';
import { useTranslation } from 'hooks/useTranslation';
import React, { useEffect, useId, useRef } from 'react';
import styles from './VideoPlayerWrapper.module.scss';
import { useRouter } from 'next/router';

interface Props {
  chatElement: React.ReactNode;
  closePlayer: () => void;
  playerElement: React.ReactNode;
}

export const VideoPlayerWrapper: React.FunctionComponent<Props> = ({
  closePlayer,
  chatElement,
  playerElement,
}) => {
  const videoPlayerHeadingId = useId();
  const chatHeadingId = useId();
  const triggerElementRef = useRef<HTMLElement>();
  const t = useTranslation();

  const router = useRouter();

  useEffect(() => {
    const onRouteChange = () => {
      closePlayer();
    };

    router.events.on('routeChangeStart', onRouteChange);

    return () => {
      router.events.off('routeChangeStart', onRouteChange);
    };
  }, [closePlayer, router]);

  useEffect(() => {
    const keydownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closePlayer();
      }
    };

    window.document.addEventListener('keydown', keydownHandler, true);

    return () => {
      window.document.removeEventListener('keydown', keydownHandler, true);
    };
  }, [closePlayer]);

  return (
    <Dialog.Root
      open
      onOpenChange={(open: boolean) => {
        if (!open) closePlayer();
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay className={styles.modalOverlay} />
        <Dialog.Content
          aria-describedby={undefined}
          className={classNames(
            styles.modalContent,
            !chatElement && styles.modalContentWithOnlyPlayer
          )}
          onOpenAutoFocus={() => {
            const triggerElement = window.document.activeElement;

            if (triggerElement instanceof HTMLElement)
              triggerElementRef.current = triggerElement;
          }}
          onCloseAutoFocus={(e) => {
            const triggerElement = triggerElementRef.current;

            if (triggerElement) {
              triggerElement.focus();
              e.preventDefault();
            } else {
              throw new Error(
                'Missing reference to the element that opened player'
              );
            }
          }}
        >
          <VisuallyHidden.Root asChild>
            <Dialog.Title>
              {chatElement ? t('videoPlayerAndChat') : t('videoPlayer')}
            </Dialog.Title>
          </VisuallyHidden.Root>

          <section
            aria-labelledby={chatElement ? videoPlayerHeadingId : undefined}
            className={styles.playerSection}
          >
            {chatElement ? (
              <VisuallyHidden.Root asChild>
                <h3 id={videoPlayerHeadingId}>{t('videoPlayer')}</h3>
              </VisuallyHidden.Root>
            ) : null}

            <div className={styles.playerInner}>{playerElement}</div>
          </section>

          {chatElement ? (
            <section
              aria-labelledby={chatHeadingId}
              className={styles.chatSection}
            >
              <VisuallyHidden.Root asChild>
                <h3 id={chatHeadingId}>{t('chat')}</h3>
              </VisuallyHidden.Root>

              {chatElement}
            </section>
          ) : null}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
