import React, { useCallback, useEffect, useId } from 'react';
import { CardImage } from 'components/Card/CardImage';
import styles from './CurrentlyPlayingCard.module.scss';
import { Card } from '@yleisradio/areena-types';
import Link from 'next/link';
import { useUILanguage } from 'hooks/useUILanguage';
import { pointerToClientURL } from 'utils/pointer';
import { RadioChannelCardContent } from 'components/Player/AudioPlayerWrapper/MinimizedAudioPlayer/RadioChannelCardContent';
import { OnDemandCardContent } from 'components/Player/AudioPlayerWrapper/MinimizedAudioPlayer/OnDemandCardContent';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useAreenaService } from 'contexts/AreenaServiceContext';
import { usePlayerState } from 'contexts/PlayerStateContext';
import ClosePlayerButton from './controls/ClosePlayerButton';

type Props = {
  ongoingCard: Card | undefined;
  upcomingCard: Card | undefined;
  isRadioChannel: boolean;
};

export const CurrentlyPlayingCard = ({
  ongoingCard: card,
  upcomingCard,
  isRadioChannel,
}: Props) => {
  const language = useUILanguage();
  const { areenaService } = useAreenaService();
  const { setActivePlayer, clearPodcastToRestore, setIsPlaying, isPlaying } =
    usePlayerState();

  const closePlayer = useCallback(() => {
    clearPodcastToRestore();
    setActivePlayer(undefined);
  }, [clearPodcastToRestore, setActivePlayer]);

  useEffect(() => {
    const keydownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closePlayer();
      }
    };

    window.document.addEventListener('keydown', keydownHandler, true);

    return () => {
      window.document.removeEventListener('keydown', keydownHandler, true);
    };
  }, [closePlayer, isPlaying, setIsPlaying]);

  const href =
    (card?.pointer &&
      pointerToClientURL(card.pointer, language, areenaService)) ||
    '';

  const titleId = useId();

  const { asPath } = useRouter();

  const currentPath = asPath.replace(/\/podcastit|\/poddar/, '');
  const isSamePage = currentPath === href;

  return (
    <div className={styles.root}>
      <Link
        href={href}
        className={classNames(
          styles.link,
          isSamePage && styles.noPointerEvents
        )}
        aria-labelledby={titleId}
      >
        <div className={styles.image}>
          <CardImage
            image={card?.image}
            imageStyle={'1:1'}
            sizes={'16vw'}
            cardPresentation={undefined}
          />
          {!isPlaying && <ClosePlayerButton closePlayer={closePlayer} />}
        </div>
        {isRadioChannel ? (
          <RadioChannelCardContent
            ongoingCard={card}
            upcomingCard={upcomingCard}
          />
        ) : (
          <OnDemandCardContent titleId={titleId} card={card} />
        )}
      </Link>
    </div>
  );
};
