import { Card } from 'components/Card';
import { Card as CardType, ListStyle } from '@yleisradio/areena-types';
import { useProgress } from 'hooks/useProgress';
import React from 'react';
import { getHistoryLabelsForCard } from 'utils/card';
import { PlaceholderCard } from 'components/PlaceholderCard';
import { useFetchQueueCard } from 'components/Player/AudioPlayerWrapper/useFetchQueueCard';

type Props = {
  itemId?: string;
  cardKey: string;
  isActive: boolean;
};

const sizes =
  '(min-width: 1920px) 18vw, (min-width: 1020px) 17vw, (min-width: 640px) 21vw, 35vw';

const listStyle: ListStyle = {
  layout: 'vertical',
  image: '1:1',
  size: 'medium',
};

type QueueCardProps = Omit<Props, 'itemId'> & { card: CardType };

const QueueCard = ({ card, cardKey, isActive }: QueueCardProps) => {
  const { historyEntries } = useProgress([card]);
  const historyLabels = getHistoryLabelsForCard(card, historyEntries);
  return (
    <Card
      card={card}
      cardKey={cardKey}
      sizes={sizes}
      listStyle={listStyle}
      historyLabels={historyLabels}
      isActive={isActive}
      isDescriptionVisible={false}
    />
  );
};

const PlayQueueCard = ({ itemId, cardKey, isActive = false }: Props) => {
  const cardByItemId = useFetchQueueCard(itemId);

  if (!cardByItemId && itemId) {
    return <PlaceholderCard listStyle={listStyle} />;
  }
  if (!cardByItemId) return null;

  return (
    <QueueCard card={cardByItemId} cardKey={cardKey} isActive={isActive} />
  );
};

export { PlayQueueCard, QueueCard };
