import styles from './NextInRecommendationsList.module.scss';
import { Card, ListStyle } from '@yleisradio/areena-types';
import { PlaceholderCard } from 'components/PlaceholderCard';
import { QueueCard } from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueueCard';
import { getCardKey } from 'utils/card';
import { AnalyticsContextProvider } from 'contexts/AnalyticsContext';
import { Labels } from 'services/yleAnalyticsSdk';
import { QUEUE_PAGE_SIZE } from 'contexts/PlayerStateContext';
import { useTranslation } from 'hooks/useTranslation';
import { Toggle } from './Toggle';

type Props = {
  heading: string;
  cards: Card[];
  analyticsContext: Labels | undefined;
  listKey: string;
  loading?: boolean;
  showRecommendationsQueue: boolean;
  setShowRecommendationsQueue: (value: boolean) => void;
};

const listStyle: ListStyle = {
  layout: 'vertical',
  image: '1:1',
  size: 'medium',
};

const NextInRecommendationsList = ({
  heading,
  cards,
  analyticsContext,
  listKey,
  loading = false,
  showRecommendationsQueue,
  setShowRecommendationsQueue,
}: Props) => {
  const t = useTranslation();

  return (
    <AnalyticsContextProvider context={analyticsContext}>
      <div className={styles.nextInListHeader}>
        <h3 className={styles.subTitle}>{heading}</h3>
        <Toggle
          id="recommendations-queue-toggle"
          checkedValue={showRecommendationsQueue}
          onCheckedChangeFunction={setShowRecommendationsQueue}
        />
      </div>
      {!showRecommendationsQueue && (
        <p className={styles.recommendationsInfoText}>
          {t('recommendationsQueueInfoText')}
        </p>
      )}
      {showRecommendationsQueue &&
        cards &&
        cards.map((card) => {
          const cardKey = getCardKey(card, listKey);
          return (
            <div key={cardKey} className={styles.cardContainer}>
              <QueueCard card={card} isActive={false} cardKey={cardKey} />
            </div>
          );
        })}

      {loading &&
        Array.from({ length: QUEUE_PAGE_SIZE }, (_, i) => (
          <li key={i} aria-hidden>
            <PlaceholderCard listStyle={listStyle} />
          </li>
        ))}
    </AnalyticsContextProvider>
  );
};

export default NextInRecommendationsList;
