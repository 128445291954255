import React from 'react';

import {
  DropdownMenu,
  Trigger,
  RadioGroup,
  RadioItem,
  Content,
  Label,
  Portal,
} from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'hooks/useTranslation';
import styles from './MoreDropdownMenu.module.scss';
import MoreVerticalIcon from 'assets/moreVerticalIcon.svg';
import { useRouter } from 'next/router';
import {
  PlaybackRateGetter,
  PlaybackRateSetter,
  PlaybackRatesGetter,
} from 'components/Player/AudioPlayerWrapper/typesAudioPlayerWrapper';

const Rate = ({ value, checked }: { value: string; checked: boolean }) => {
  return (
    <div className={styles.rate}>
      <div className={styles.checkboxContainer}>
        <div className={styles.checkbox}>
          {checked && <div className={styles.checked} />}
        </div>
      </div>
      <div className={styles.rateValue}>{value}</div>
    </div>
  );
};

type Props = {
  getPlaybackRates: PlaybackRatesGetter;
  getPlaybackRate: PlaybackRateGetter;
  setPlaybackRate: PlaybackRateSetter;
};

const Menu = ({
  getPlaybackRates,
  getPlaybackRate,
  setPlaybackRate,
}: Props) => {
  const { locale } = useRouter();

  const playbackRates = getPlaybackRates();
  const playbackRate = getPlaybackRate();
  const t = useTranslation();

  return (
    <DropdownMenu modal={false}>
      <Trigger aria-label={t('menu')} className={styles.trigger}>
        <MoreVerticalIcon aria-hidden={true} className={styles.triggerIcon} />
      </Trigger>
      <Portal>
        {playbackRates ? (
          <Content className={styles.content} align="end">
            <div className={styles.labelContainer}>
              <Label className={styles.label}>{t('playbackRate')}</Label>
              <hr className={styles.divider} />
            </div>
            <RadioGroup
              className={styles.radioGroup}
              value={`${playbackRate.toLocaleString()}`}
            >
              {playbackRates.map((rate) => {
                const stringValue = rate.toLocaleString(locale);
                return (
                  <RadioItem
                    aria-label={`${stringValue}x`}
                    value={stringValue}
                    key={rate}
                    onSelect={() => setPlaybackRate(rate)}
                  >
                    <Rate
                      checked={playbackRate === rate}
                      value={
                        rate === 1 ? t('playbackRateNormal') : `${stringValue}x`
                      }
                    />
                  </RadioItem>
                );
              })}
            </RadioGroup>
          </Content>
        ) : null}
      </Portal>
    </DropdownMenu>
  );
};

export default Menu;
