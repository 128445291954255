import React from 'react';
import styles from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueue.module.scss';
import { useTranslation } from 'hooks/useTranslation';
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import animationData from 'components/Player/AudioPlayerWrapper/PlayQueue/animationData.json';

const EmptyPlayQueue = () => {
  const t = useTranslation();
  return (
    <div className={styles.emptyPlayQueueContainer}>
      <div>
        <LottiePlayer
          autoplay
          loop
          src={animationData}
          style={{ height: '250px', width: '250px' }}
        ></LottiePlayer>
        <p className={styles.emptyPlayQueueText}>{t('emptyQueue')}</p>
      </div>
    </div>
  );
};

export default EmptyPlayQueue;
