import styles from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueue.module.scss';
import { useTranslation } from 'hooks/useTranslation';
import { PlayQueueCard } from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueueCard';

type Props = {
  itemIds: string[];
};

const NextInQueueList = ({ itemIds }: Props) => {
  const t = useTranslation();

  return (
    <div>
      <h3 className={styles.subTitle}>{t('nextInQueue')}</h3>
      {itemIds &&
        itemIds.map((itemId) => {
          const cardKey = `play-queue-next-in-queue-${itemId}`;
          return (
            <div key={cardKey} className={styles.cardContainer}>
              <PlayQueueCard
                itemId={itemId}
                isActive={false}
                cardKey={cardKey}
              />
            </div>
          );
        })}
    </div>
  );
};

export default NextInQueueList;
