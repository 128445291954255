import styles from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueue.module.scss';
import { useTranslation } from 'hooks/useTranslation';
import { PlayQueueCard } from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueueCard';
import { usePlayerState } from 'contexts/PlayerStateContext';

const CurrentlyPlaying = () => {
  const t = useTranslation();
  const { currentlyPlayingId } = usePlayerState();

  if (!currentlyPlayingId) return null;

  return (
    <div>
      <h3 className={styles.subTitle}>{t('nowPlaying')}</h3>
      <div className={styles.cardContainer}>
        <PlayQueueCard
          itemId={currentlyPlayingId}
          isActive={true}
          cardKey={`playlist-currently-playing`}
        />
      </div>
    </div>
  );
};

export default CurrentlyPlaying;
