// This body class is defined in styles/global.scss
const className = 'preventScroll';

export const enableBodyScroll = () => {
  document.body.classList.remove(className);
};

export const disableBodyScroll = () => {
  document.body.classList.add(className);
};
